<template>
  <div>
    <validation-observer
      ref="infoRules"
      tag="form"
    >
      <b-row>
        <b-col md="12">
          <!-- Image -->
          <div class="border rounded p-2">
            <h4 class="mb-1">
              Category image
            </h4>
            <b-media
              no-body
              vertical-align="center"
              class="flex-column flex-md-row"
            >
              <b-media-aside>
                <b-img
                  :src="preview"
                  height="200"
                  width="200"
                  class="rounded mr-2 mb-1 mb-md-0"
                />
              </b-media-aside>
              <b-media-body>
                <b-card-text class="my-50" />
                <div class="d-inline-block">
                  <b-form-file
                    accept=".jpg, .png, .gif"
                    placeholder="Choose image"
                    @change="onChange($event)"
                  />
                </div>
              </b-media-body>
            </b-media>
          </div>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            v-if="!loader"
            variant="primary"
            class="mr-1"
            @click="editCategoryAggregators()"
          >
            Save
          </b-button>
          <b-button
            v-if="loader"
            variant="primary"
            disabled
            class="mr-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Loading...
          </b-button>
          <b-button
            variant="outline-danger"
            class="closeModal"
            @click="closeModal()"
          >
            Cancel
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  data() {
    return {
      id: this.$store.state.generalIds.id,
      loader: false,
      required,
      preview: null,
      url: 'https://stage.zerocash.co/storage/',

    }
  },
  setup() {
    const { getValidationState } = formValidation()
    const { formData, setFormData } = handleFormData()
    const image = ref('')

    const categoryForm = ref({
    })
    return {
      getValidationState,
      categoryForm,
      setFormData,
      formData,
      image,
    }
  },
  mounted() {
    this.viewCategoryAggregators()
  },
  methods: {
    closeModal() {
      this.categoryForm = {
      }
      this.$store.commit('generalIds/SET_ID', null)
      this.$bvModal.hide('damenCategory')
    },
    editCategoryAggregators() {
      if (this.id && this.image) {
        this.formData.append('image', this.image)
        this.formData.append('_method', 'PUT')
        axios.post(`categoryAggregators/${this.id}`, this.formData).then(res => {
          if (res.status === 200 || res.status === 201) {
            this.$parent.$parent.$parent.$refs.damenCategory.getAllData()
            this.closeModal()
            this.$toasted.show('Updated Successfully', {
              position: 'top-center',
              duration: 3000,
            })
          }
        })
      }
    },
    viewCategoryAggregators() {
      if (this.id) {
        axios.get(`categoryAggregators/${this.id}`).then(res => {
          if (res.status === 200) {
            this.categoryForm = res.data?.data
            this.preview = this.url + res.data?.data.image
          }
        })
      }
    },

    onChange(e) {
      // eslint-disable-next-line prefer-destructuring
      this.image = e.target.files[0]
      const input = e.target
      if (input.files) {
        const reader = new FileReader()
        reader.onload = er => {
          this.preview = er.target.result
        }
        // eslint-disable-next-line prefer-destructuring
        this.image = input.files[0]
        reader.readAsDataURL(input.files[0])
      }
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
